import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer, Box, Flex, Text, Button, Heading, Circle, useToast } from '@chakra-ui/react';
import { InfoIcon, LockIcon } from '@chakra-ui/icons';
import InfoBasicas from '../../../components/DepositoDias/InfoBasicas';
import Pagamento from '../../../components/DepositoDias/Pagamento';
import { useOwnershipData } from '../../../fetchs/ownership';

import { apiCPT, apiVindiPublicKey } from '../../../services/api';

import LoaderDeposit from '../../../components/LoaderDeposit';
import moment from 'moment';

export default function DepositoDias() {
  const toast = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [loadLocalStorage, setLoadLocalStorage] = useState(false);
  const [applyNextStep, setApplyNextStep] = useState(false);
  const [applyFinishDeposit, setApplyFinishDeposit] = useState(false);
  const [loadPayment, setLoadPayment] = useState(false);
  const [viajex, setViajex] = useState(0);

  const [depositData, setDepositData] = useState([
    { id: 1, cota: '', quartos: '', hospedes: '', check_in: '', check_out: '', ownership_id: null },
  ]);

  // Função para buscar o ownership_id e associar a cada semana
  const fetchOwnershipId = async (habitational_unit_id, ano) => {
    try {
      const response = await fetch(
        `https://qabk.ddns.net/v1/extract/weekly_deposits/1?habitational_unit_id=${habitational_unit_id}&pageNumber=1&perPage=10&ano=${ano}`,
      );
      const data = await response.json();

      if (data?.ownership) {
        console.log('Ownership data:', data);
        setDepositData(prevState =>
          prevState.map(item => ({
            ...item,
            ownership_id: data.ownership.id,
          })),
        );
      }
    } catch (error) {
      console.error('Erro ao buscar o ownership_id:', error);
    }
  };

  // Função para alterar campos de uma semana específica (índice específico)
  const handleWeekChange = (index, field, value) => {
    console.log(`Alterando semana no índice: ${index}, campo: ${field}, valor: ${value}`);

    setDepositData(prevState => {
      const updatedDepositData = prevState.map((item, i) => (i === index ? { ...item, [field]: value } : { ...item }));

      console.log('Novo estado de depositData:', updatedDepositData); // Debug para verificar o novo estado

      return updatedDepositData;
    });
  };

  // Função para validar se as semanas não se sobrepõem
  const validateWeekDates = () => {
    for (let i = 0; i < depositData.length - 1; i++) {
      const currentWeek = depositData[i];
      const nextWeek = depositData[i + 1];

      if (moment(currentWeek.check_out).isSameOrAfter(moment(nextWeek.check_in))) {
        // Se a data de saída for igual ou posterior à data de entrada da próxima semana, há sobreposição
        toast({
          title: `A semana ${i + 1} tem conflito com a semana ${i + 2}. Por favor, ajuste as datas.`,
          position: 'top',
          status: 'error',
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  // Função para ajustar automaticamente as datas e evitar sobreposição
  const adjustWeekDates = () => {
    setDepositData(prevState => {
      return prevState.map((week, index) => {
        if (index > 0) {
          const previousWeek = prevState[index - 1];
          const newCheckIn = moment(previousWeek.check_out).add(1, 'days').format('YYYY-MM-DD');
          if (moment(week.check_in).isSameOrBefore(previousWeek.check_out)) {
            return {
              ...week,
              check_in: newCheckIn,
            };
          }
        }
        return week;
      });
    });
  };

  // Função para validar se pode avançar
  const handleNext = () => {
    // Ajustar automaticamente as datas antes de validar
    adjustWeekDates();

    // Validar se as semanas estão corretas
    if (!validateWeekDates()) {
      return;
    }

    if (!applyNextStep) {
      toast({
        title: 'Para avançar, preencha todas as informações.',
        position: 'top',
        status: 'warning',
        isClosable: true,
      });
    } else {
      setStep(prevStep => prevStep + 1);
    }
  };

  // Voltar para a etapa anterior
  const handleBack = () => {
    setLoadLocalStorage(true);
    setStep(prevStep => Math.max(prevStep - 1, 1));
  };

  // Função para realizar o pagamento
  const handleDoPayment = () => {
    console.log('Iniciando handleDoPayment'); // Log inicial

    // Verificar se o depósito pode ser finalizado
    if (applyFinishDeposit) {
      console.log('applyFinishDeposit é true'); // Confirma que pode finalizar

      const userData = JSON.parse(localStorage.getItem('__user_data'));
      const cardPaymentData = JSON.parse(localStorage.getItem('__client_card_payment'));
      const typeDeposit = JSON.parse(localStorage.getItem('__client_type_of_deposit'));

      console.log('userData:', userData);
      console.log('cardPaymentData:', cardPaymentData);
      console.log('typeDeposit:', typeDeposit);

      // Verificar se o usuário tem um vindi_customer_id
      if (userData?.vindi_customer_id) {
        console.log('Usuário possui vindi_customer_id:', userData.vindi_customer_id);

        // Verificar o conteúdo de depositData
        console.log('depositData:', depositData);

        // Iterar sobre os dados de depósito
        for (const item of depositData) {
          console.log('Processando item:', item);

          // Verificar se há um ownership_id válido
          if (item?.onwership_id) {
            console.log('Ownership ID encontrado:', item.onwership_id);

            const payloadPutDeposit = {
              start_at: item.check_in,
              end_at: item.check_out,
            };

            console.log('Payload para PUT request:', payloadPutDeposit);

            // Chamada PUT para atualizar ownership
            apiCPT
              .put(`/v1/ownership/put/${item.onwership_id}`, payloadPutDeposit)
              .then(resPutOwnerShip => {
                console.log('Resposta do PUT ownership:', resPutOwnerShip.data);

                // Criar o perfil de pagamento
                apiVindiPublicKey
                  .post('/payment_profiles', {
                    holder_name: cardPaymentData.name,
                    card_expiration: cardPaymentData.expiry,
                    card_number: cardPaymentData.number,
                    card_cvv: cardPaymentData.cvv,
                    payment_method_code: 'credit_card',
                    payment_company_code: cardPaymentData.issuer,
                    customer_id: userData?.vindi_customer_id,
                  })
                  .then(resPostPaymentProfiles_1 => {
                    console.log('Resposta do primeiro POST payment_profiles:', resPostPaymentProfiles_1.data);

                    if (resPostPaymentProfiles_1.data) {
                      apiCPT
                        .post('/v1/payment_profiles', {
                          gateway_token: resPostPaymentProfiles_1.data?.payment_profile?.gateway_token,
                          payment_method_code: 'credit_card',
                          customer_id: userData?.vindi_customer_id,
                        })
                        .then(resPostPaymentProfiles_2 => {
                          console.log('Resposta do segundo POST payment_profiles:', resPostPaymentProfiles_2.data);

                          apiCPT
                            .post('/v1/bills', {
                              customer_id: userData?.vindi_customer_id,
                              payment_method_code: 'credit_card',
                              gateway_token: resPostPaymentProfiles_1.data?.payment_profile?.gateway_token,
                              bill_items: [
                                {
                                  product_id: typeDeposit.product_vindi_id,
                                  amount: typeDeposit.value,
                                },
                              ],
                            })
                            .then(resBills => {
                              console.log('Resposta POST bills:', resBills.data);

                              apiCPT
                                .post('/v1/transactions', {
                                  user_id: userData?.user_id,
                                  ownership_id: Number(item.onwership_id),
                                  product_id: typeDeposit.product_vindi_id,
                                  value: typeDeposit.value,
                                  tid: resBills.data.bill.charges[0]?.last_transaction.id,
                                  vindi_tgw_id_token:
                                    resBills.data.bill.charges[0]?.last_transaction.gateway_transaction_id,
                                  NSU: resBills.data.bill.charges[0]?.last_transaction.gateway_response_fields.nsu,
                                  charge_id: resBills.data.bill.charges[0]?.id,
                                })
                                .then(resTransaction => {
                                  console.log('Resposta POST transactions:', resTransaction.data);
                                  const typeAbrangency = JSON.parse(
                                    localStorage.getItem('__client_type_of_abrangency'),
                                  );
                                  // Chamada POST para /v1/cashclubs após sucesso na transação
                                  apiCPT
                                    .post('/v1/cashclubs', {
                                      user_id: userData?.user_id,
                                      ownership_id: Number(item.onwership_id),
                                      transaction_type: 'D',
                                      value: typeDeposit.value,
                                      expire_at: moment().add(18, 'months').format('YYYY-MM-DD'),
                                      habitational_unity_name: resPutOwnerShip?.data.which_one_hu,
                                      abrangency: typeAbrangency?.value === 'N' ? 'N' : 'I', // Usar 'N' como padrão se não estiver definido
                                      cashclubs: item.viajex,
                                    })
                                    .then(resCashClubs => {
                                      console.log('Resposta POST cashclubs:', resCashClubs.data);
                                    })
                                    .catch(err => {
                                      console.error('Erro ao processar POST cashclubs:', err);
                                    });
                                })
                                .catch(err => {
                                  console.error('Erro ao processar POST transactions:', err);
                                });
                            })
                            .catch(err => {
                              console.error('Erro ao processar POST bills:', err);
                            });
                        })
                        .catch(err => {
                          console.error('Erro ao processar segundo POST payment_profiles:', err);
                        });
                    }
                  })
                  .catch(err => {
                    console.error('Erro ao processar primeiro POST payment_profiles:', err);
                  });
              })
              .catch(err => {
                console.error('Erro no PUT ownership:', err);
              });
          } else {
            console.warn('Ownership ID ausente para item:', item);
          }
        }
      } else {
        console.warn('Usuário não possui vindi_customer_id');
        toast({
          title: 'Usuário não possui credencial na VINDI.',
          position: 'top',
          status: 'warning',
          isClosable: true,
        });
      }
    } else {
      console.warn('applyFinishDeposit é false, depósito não pode ser finalizado.');
    }

    // Atualizar estado de carregamento e exibir toast de sucesso
    setLoadPayment(true);
    setTimeout(() => {
      setLoadPayment(false);
      toast({
        title: 'Depósito realizado com sucesso.',
        position: 'top',
        status: 'success',
        isClosable: true,
      });
      navigate('/panel/dashboard');
    }, 3500);
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('__user_data');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }

    fetchOwnershipId(2, 2024);
  }, []);

  const { data, requestApiCPT, loading } = useOwnershipData(userData?.user_id);

  const [requestMade, setRequestMade] = useState(false);

  useEffect(() => {
    const fetchOwnershipData = async () => {
      if (userData.user_id !== undefined && !requestMade && !loading) {
        await requestApiCPT('get');
        setRequestMade(true);
      }
    };

    fetchOwnershipData();
  }, [userData.user_id, requestApiCPT, loading, requestMade]);

  const getComponentByStep = () => {
    switch (step) {
      case 1:
        return (
          <InfoBasicas
            enterprises={data && data?.ownership}
            loadLocalStorage={loadLocalStorage}
            setLoadLocalStorage={setLoadLocalStorage}
            setApplyNextStep={setApplyNextStep}
            depositData={depositData}
            setDepositData={setDepositData}
            handleWeekChange={handleWeekChange}
            setViajex={setViajex}
          />
        );
      case 2:
        return <Pagamento applyFinishDeposit={applyFinishDeposit} setApplyFinishDeposit={setApplyFinishDeposit} />;
      default:
        return null;
    }
  };

  const getStepInfo = () => {
    const steps = [
      { label: 'Informações básicas', icon: <InfoIcon boxSize={6} />, isActive: step === 1 },
      { label: 'Pagamento e confirmação', icon: <LockIcon boxSize={6} />, isActive: step === 2 },
    ];

    return steps.map(s => ({
      ...s,
      borderColor: s.isActive ? '#ECC94B' : '#A0AEC0',
    }));
  };

  if (loadPayment === true) {
    return (
      <Flex flexDir="column" ml="4vw" w="73vw">
        <LoaderDeposit />
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" ml="4vw" w="73vw">
      <Box p="20px" borderRadius="15px" bg="#FFFFFF" mt="20px" mb="100px">
        <Box>
          <Flex align="center" mb={5}>
            <Heading as="h1" size="lg">
              Depositar semana(s)
            </Heading>
          </Flex>

          <Box p={6} w="100%" h="100%">
            <Box mt={4}>
              <Flex w="100%">
                {getStepInfo().map((s, index) => (
                  <Box key={index} w="33%" p={2}>
                    <Flex alignItems="center" flexDirection="column">
                      <Circle
                        size="64px"
                        bg={s.isActive ? '#ECC94B' : '#A0AEC0'}
                        color="white"
                        borderWidth="2px"
                        borderColor={s.borderColor}
                        borderRadius="50%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {s.icon}
                      </Circle>
                      <Box mt={2}>
                        <Text color={s.isActive ? '#ECC94B' : '#A0AEC0'} fontWeight={700}>
                          {s.label}
                        </Text>
                        <Text>{s.isActive ? 'Você está aqui' : ''}</Text>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Flex>
            </Box>
            <Box mt={6}>{getComponentByStep()}</Box>
            <Flex w="100%" mt={4}>
              <Box>
                {step > 1 && (
                  <Button onClick={handleBack} variant="outline">
                    Voltar
                  </Button>
                )}
              </Box>
              <Spacer />
              <Box>
                {step < 2 ? (
                  <Button onClick={handleNext} colorScheme="red" disabled={viajex === 0}>
                    Avançar
                  </Button>
                ) : (
                  <Button colorScheme="red" onClick={handleDoPayment}>
                    Finalizar
                  </Button>
                )}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
