/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
} from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

function ReactTable({ columns, data, totaPages, pageNumber, onChange }) {
  console.log('data', data);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [canNextPage, setCanNextPage] = React.useState(true);
  const [canPreviousPage, setCanPreviousPage] = React.useState(true);
  const [sPageSize, setPageSize] = React.useState(10);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    pageOptions,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      totaPages,
    },
    usePagination,
  );

  React.useEffect(() => {
    const temp = totaPages - 1;
    setCanNextPage(pageIndex < temp);
    setCanPreviousPage(pageIndex > 0);
  }, [pageIndex, totaPages]);

  onChange({
    pageIndex,
    pageSize,
    pageOptions,
    pageNumber,
    sPageSize,
  });

  const tableRef = useRef(null);

  const handleScrollLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const hasHorizontalScroll = tableRef.current.scrollWidth > tableRef.current.clientWidth;
        setShowScrollButtons(hasHorizontalScroll);
      }
    };

    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const scrollButtonStyle = {
    height: '100%',
    cursor: 'pointer',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s',
    backgroundColor: showScrollButtons ? '#eee' : 'transparent',
  };

  return (
    <>
      {showScrollButtons === false ? (
        <>
          <Table
            boxShadow="0px 5px 10px #2D3748"
            borderRadius={10}
            {...getTableProps()}
            size="sm"
            style={{
              margin: '10px',
              fontSize: '16px',
              borderBottom: '1px solid #2D3748',
            }}
          >
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th {...column.getHeaderProps()} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#f9f9f9' : '#fff',
                      borderBottom: i === rows.length - 1 ? 'none' : '1px solid #2D3748',
                    }}
                  >
                    {row.cells.map(cell => (
                      <Td borderRadius={10} fontSize={16} {...cell.getCellProps()} style={{ padding: '15px' }}>
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Flex justifyContent="space-between" m={4} alignItems="center">
            <Flex>
              <Tooltip label="Primeira página">
                <IconButton
                  onClick={() => setPageIndex(0)}
                  isDisabled={!canPreviousPage}
                  icon={<ArrowLeftIcon h={3} w={3} />}
                  mr={4}
                />
              </Tooltip>
              <Tooltip label="Página anterior">
                <IconButton
                  onClick={() => setPageIndex(pageIndex - 1)}
                  isDisabled={!canPreviousPage}
                  icon={<ChevronLeftIcon h={6} w={6} />}
                />
              </Tooltip>
            </Flex>

            <Flex alignItems="center">
              <Text flexShrink="0" mr={8}>
                Página{' '}
                <Text fontWeight="bold" as="span">
                  {pageNumber}
                </Text>{' '}
                de{' '}
                <Text fontWeight="bold" as="span">
                  {Math.ceil(totaPages)}
                </Text>
              </Text>
              <Text flexShrink="0">Ir para página:</Text>{' '}
              <NumberInput
                ml={2}
                mr={8}
                w={28}
                min={1}
                max={totaPages}
                onChange={value => {
                  const page = value ? value - 1 : 0;
                  setPageIndex(page);
                }}
                defaultValue={pageIndex + 1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select
                w={32}
                value={sPageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>

            <Flex>
              <Tooltip label="Próxima Página">
                <IconButton
                  onClick={() => setPageIndex(pageIndex + 1)}
                  isDisabled={!canNextPage}
                  icon={<ChevronRightIcon h={6} w={6} />}
                />
              </Tooltip>
              <Tooltip label="Última Página">
                <IconButton
                  onClick={() => setPageIndex(totaPages - 1)}
                  isDisabled={!canNextPage}
                  icon={<ArrowRightIcon h={3} w={3} />}
                  ml={4}
                />
              </Tooltip>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex mt={5} mb={5} justify="space-between" alignItems="center">
          <Box>
            <BiChevronLeft
              fontSize={25}
              size={25}
              cursor="pointer"
              onClick={handleScrollLeft}
              style={scrollButtonStyle}
            />
          </Box>
          <div ref={tableRef} style={{ overflowX: 'auto', marginBottom: 100 }}>
            <Flex mt={5} mb={5} justify="space-between" alignItems="center">
              <Table {...getTableProps()} size="sm">
                <Thead>
                  {headerGroups.map(headerGroup => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Flex>
            <Flex justifyContent="space-between" m={4} alignItems="center">
              <Flex>
                <Tooltip label="Primeira página">
                  <IconButton
                    onClick={() => setPageIndex(0)}
                    isDisabled={!canPreviousPage}
                    icon={<ArrowLeftIcon h={3} w={3} />}
                    mr={4}
                  />
                </Tooltip>
                <Tooltip label="Página anterior">
                  <IconButton
                    onClick={() => setPageIndex(pageIndex - 1)}
                    isDisabled={!canPreviousPage}
                    icon={<ChevronLeftIcon h={6} w={6} />}
                  />
                </Tooltip>
              </Flex>

              <Flex alignItems="center">
                <Text flexShrink="0" mr={8}>
                  Página{' '}
                  <Text fontWeight="bold" as="span">
                    {pageNumber}
                  </Text>{' '}
                  de{' '}
                  <Text fontWeight="bold" as="span">
                    {Math.ceil(totaPages)}
                  </Text>
                </Text>
                <Text flexShrink="0">Ir para página:</Text>{' '}
                <NumberInput
                  ml={2}
                  mr={8}
                  w={28}
                  min={1}
                  max={totaPages}
                  onChange={value => {
                    const page = value ? value - 1 : 0;
                    setPageIndex(page);
                  }}
                  defaultValue={pageIndex + 1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select
                  w={32}
                  value={sPageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </Select>
              </Flex>

              <Flex>
                <Tooltip label="Próxima Página">
                  <IconButton
                    onClick={() => setPageIndex(pageIndex + 1)}
                    isDisabled={!canNextPage}
                    icon={<ChevronRightIcon h={6} w={6} />}
                  />
                </Tooltip>
                <Tooltip label="Última Página">
                  <IconButton
                    onClick={() => setPageIndex(totaPages - 1)}
                    isDisabled={!canNextPage}
                    icon={<ArrowRightIcon h={3} w={3} />}
                    ml={4}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </div>
          <Box>
            <BiChevronRight
              fontSize={25}
              size={25}
              cursor="pointer"
              onClick={handleScrollRight}
              style={scrollButtonStyle}
            />
          </Box>
        </Flex>
      )}
    </>
  );
}

export default ReactTable;
