import { useToast } from '@chakra-ui/react';

import moment from 'moment';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { apiCNG, apiCommons, apiCPT } from '../services/api';
import { getDoBooking } from '../fetchs/book';
import { getTransaction, getReservation, getVoucher, getPoints, getMyUser } from '../fetchs/cpt';
import { getPayment, getProfile, getRefund, getTokenCard } from '../fetchs/vindi';
import { filterRooms } from '../utils/hotelsSearch';
import { useNavigate } from 'react-router-dom';
import useCustomToast from '../utils/toasts';
import { useCotation } from './CotationProvider';

export const SearchContext = createContext(null);

export function SearchProvider({ children }) {
  const toast = useToast();
  const customToast = useCustomToast();
  const navigate = useNavigate();
  const { cotation, markupC } = useCotation();

  const [hotels, setHotels] = useState([]);
  const [hotelDetailsC, setHotelDetailsC] = useState([]);
  const [resetTimer, setResetTimer] = useState('');

  const [cancel, setCancel] = useState([]);
  const [mnPrice, setMnPrice] = useState(10);
  const [mxPrice, setMxPrice] = useState(999999);
  const [nNights, setNNights] = useState(1);
  const [roomOnly, setRoomOnly] = useState(true);
  const [sR, setSR] = useState([]);
  const [nationality, setNationality] = useState('BR');
  const [destinations, setDestinations] = useState([]);
  const [destinationId, setDestinationID] = useState('');
  const [hotelID, setHotelID] = useState(null);
  const [error, setError] = useState('');
  const [errorPlayBook, setErrorPlayBook] = useState([]);
  const [isErrorBookIn, setIsErrorBookIn] = useState({
    type: 'Generic',
    title: 'Ops!',
    message: 'Por favor, entre em contato com a Club Share',
  });
  //SEARCH
  const [search, setSearch] = useState('');
  const [loadingD, setLoadingD] = useState(false);
  const [loadingB, setLoadingB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date(Date.now() + 3600 * 1000 * 24)]);
  const [rooms, setRooms] = useState([{ numberOfAdults: [1], childrenAge: [], quantity: 1 }]);
  const [filterPrice, setFilterPrice] = useState('');
  const [isFirstSrc, setIsFirstSrc] = useState(false);
  const [bookingId, setBookingId] = useState(0);

  //BOOK CANGOOROO
  const [doBookingPolice, setDoBookingPolice] = useState([]);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [doBooking, setDoBooking] = useState([]);
  const [doBookingStatus, setDoBookingStatus] = useState(null);
  const [isBooking, setIsBooking] = useState(JSON.parse(localStorage?.getItem('dobooking')));
  const [bookError, setBookError] = useState(false);
  const [token, setToken] = useState([]);
  const [loadingC, setLoadingC] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  //VINDI
  const [paymentData, setPaymentData] = useState([]);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [profile, setProfile] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [tokenCard, setTokenCard] = useState([]);
  const [loadingTokenC, setLoadingTokenC] = useState(false);
  const [canBook, setCanBook] = useState(false);

  //MENSAGENS PADRÃO
  const insuficient_founds = 'Transação não autorizada, verifique os dados e o saldo do seu cartão!';
  const unauth_transaction = 'Transação não autorizada';
  const refund_card = 'Seu saldo utilizado será estornado!';

  //PRODUCTS
  const [products, setProducts] = useState([]);

  //AUTOCOMPLETE COMMONS
  const onChanged = async src => {
    try {
      setLoadingD(true);
      const res = await apiCommons.get(
        `/mapping/autocomplete/all/${src}/managerid/469/token/fdaa4256-1e4d-40c6-aa1c-1eaeafdbff04`,
      );
      if (res.status !== 204) {
        setDestinations(res?.data);
      }
      setLoadingD(false);
    } catch (e) {
      setDestinations([]);
      setError(e);
      setLoadingD(false);
    } finally {
      setLoadingD(false);
    }
  };

  //Pesquisa de hotels
  const getSearchHotels = async ({
    hotelID,
    id,
    minimunPrice,
    numNights,
    maxPrice,
    roomsOnly,
    sRooms,
    nation,
    firstSearch,
  }) => {
    try {
      await setLoading(true);
      let hotelIdJson; // Declare a variável hotelIdJson aqui
      let noHotelIdJson;

      hotelIdJson = {
        Criteria: {
          DestinationId: `${id}`,
          HotelsIds: hotelID != 0 ? [hotelID] : null,
          MainPaxCountryCodeNationality: nation,
          CheckinDate: selectedDates[0] ? moment(selectedDates[0]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          NumNights: numNights === 1 ? numNights : numNights,
          ReturnHotelStaticData: true,
          Timeout: '60000',
          Filters: {
            MinPrice: minimunPrice,
            MaxPrice: maxPrice,
            CheapestRoomOnly: roomsOnly,
          },
          SearchRooms: filterRooms(sRooms),
        },
      };
      noHotelIdJson = {
        Criteria: {
          DestinationId: `${id}`,
          MainPaxCountryCodeNationality: nation,
          CheckinDate: selectedDates[0] ? moment(selectedDates[0]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          NumNights: numNights === 1 ? numNights : numNights,
          ReturnHotelStaticData: true,
          Timeout: '60000',
          Filters: {
            MinPrice: minimunPrice,
            MaxPrice: maxPrice,
            CheapestRoomOnly: roomsOnly,
          },
          SearchRooms: filterRooms(sRooms),
        },
      };

      const res = await apiCNG.post('/Hotel.svc/Search', hotelID ? hotelIdJson : noHotelIdJson);
      setNNights(numNights === 1 ? numNights : numNights);
      if (res.status === 201 || res.status === 200) {
        await setHotels(res?.data);
        await setLoading(false);
        await localStorage.setItem('hotelStaticData', JSON.stringify(res?.data));
        await setResetTimer('reset');
        if (firstSearch) {
          setIsFirstSrc(true);
        } else {
          setIsFirstSrc(false);
        }
      }
    } catch (err) {
      await setLoading(false);
      await console.error(err);
    }
  };

  localStorage.setItem('__hotel_id', JSON.stringify(destinationId));

  // Detalhes do hotel
  const getHotelDetailsCangoroo = async ({ hotelId, minimunPrice, numNights, maxPrice, sRooms, nation }) => {
    try {
      await setLoadingC(true);
      const res = await apiCNG.post('/Hotel.svc/Search', {
        Criteria: {
          DestinationId: JSON.parse(localStorage.getItem('__hotel_id')),
          HotelsIds: [hotelId],
          MainPaxCountryCodeNationality: nation,
          CheckinDate: selectedDates[0] ? moment(selectedDates[0]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          NumNights: numNights === 1 ? numNights : numNights,
          ReturnHotelStaticData: true,
          Timeout: '10000',
          Filters: {
            MinPrice: minimunPrice - 1,
            MaxPrice: maxPrice + 1,
            CheapestRoomOnly: false,
          },
          SearchRooms: filterRooms(sRooms),
        },
      });
      setNNights(numNights === 1 ? numNights : numNights);
      if (res.status === 201 || res.status === 200) {
        await setHotelDetailsC(res?.data);
        localStorage.setItem('__booking_filters', JSON.stringify({ hotelId: hotelId, token: res?.data?.Token }));
      }
      await setLoadingC(false);
    } catch (err) {
      await setLoadingC(false);
      await console.error(err);
    }
  };

  let isBookingConditionsPending = false;

  //Verifica a possibilidade de reserva
  const getBookingConditions = async ({ hotelId, roomId, token }) => {
    try {
      // Verificar se uma solicitação está em andamento
      if (isBookingConditionsPending) {
        console.log('Aguarde a conclusão da solicitação anterior.');
        return;
      }

      // Iniciar a solicitação
      isBookingConditionsPending = true;

      await setLoadingC(true);
      await toast({
        title: 'Aguarde',
        description: 'Estamos processando e validando a disponibilidade da sua reserva',
        status: 'info',
        duration: 11000,
        isClosable: false,
      });

      const res = await apiCNG.post('/Hotel.svc/getBookingConditions', {
        HotelId: hotelId,
        RoomIds: [roomId],
        Token: token,
      });

      if (res.status === 200 && res.data.Token) {
        await toast.closeAll();
        await setLoadingC(false);
        await toast({
          title: 'Ok!',
          description: 'Verificação feita com sucesso',
          status: 'success',
          duration: 11000,
          isClosable: false,
        });
        await setDoBookingPolice(res?.data);
      } else {
        await toast.closeAll();
        await toast({
          title: 'Ops',
          description: 'Algo deu errado! Tente novamente mais tarde!',
          status: 'error',
          duration: 11000,
          isClosable: false,
        });
      }
    } catch (err) {
      await toast.closeAll();
      await toast({
        title: 'Ops',
        description: 'Algo deu errado! Tente novamente mais tarde!',
        status: 'error',
        duration: 11000,
        isClosable: false,
      });
    } finally {
      // Marcar a solicitação como concluída, independentemente do resultado
      isBookingConditionsPending = false;
    }
  };

  const getProducts = async () => {
    try {
      const res = await apiCPT.get('/v1/products');
      await setProducts(res?.data?.products);
      return res;
    } catch (err) {
      await console.error(err);
    }
  };

  // Caso tenha sucesso na reserva
  const getSucessBook = async ({ resBook, user_id, payment, token, doBookingPolice, points, useCard, priceCard }) => {
    try {
      await setDoBooking(resBook?.data);
      await setDoBookingStatus(resBook?.status ?? '');
      await setLoadingB(false);
      await window.scrollTo(0, 0);
      await customToast.toastSucessBook();
      await setShowCancelButton(true);
      await setBookingId(resBook?.data?.Rooms[0]?.ReservationId);
      await localStorage.setItem('__bookingId', JSON.stringify(resBook?.data?.Rooms[0]?.ReservationId));
      // Registra uma transação, tanto apenas efetuada integralmente me CashClubs ou mista
      // Ou seja cartão e cashclubs
      const prod = products && products?.filter(x => x.description == 'Hotelaria');
      const transaction = await getTransaction({
        user_id: user_id,
        product_id: prod?.product_id,
        cc_id: points?.data?.id,
        charge_id: payment !== false ? payment?.data?.bill?.charges[0]?.id : null,
        // Se a Transação é feita com cartão e CashClubs, o valor a ser enviado deverá ser o mesmo da cobrança
        // Se a Transação é apenas com cartão, o valor será integral a reserva
        value: useCard ? priceCard : Math.ceil(doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value),
        tid: payment !== false ? payment?.data?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.tid : null,
        vindi_tgw_id_token: token,
        vindi_tid: payment !== false ? payment?.data?.bill?.charges[0]?.last_transaction?.id : null,
        NSU: payment !== false ? payment?.data?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.nsu : null,
      });

      const reservation = await getReservation({
        id: resBook?.data?.RequestId,
        id_user: user_id,
        cng_booking_number: resBook?.data?.Rooms[0]?.ReservationId,
        cng_service_id: resBook?.data?.Rooms[0]?.ServiceId,
        checkin_date: selectedDates[0] ? moment(selectedDates[0]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        checkout_date: selectedDates[1] ? moment(selectedDates[1]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        cc_id: points?.data?.id,
        transaction_id: transaction?.data?.transaction_id,
        status: 1,
        label_hotel_name: resBook?.data?.Rooms[0]?.HotelName,
        observation: 'Reserva efetuado com sucesso',
      });

      console.log(reservation, 'reservation');

      const voucher = await getVoucher({ book_id: resBook?.data?.Rooms[0]?.ReservationId, user_id: user_id });
      console.log(voucher, 'voucher');
      await getMyUser({ id: user_id });
      if (voucher.status === 200 || voucher.status === 201) {
        await navigate('/bookSuccess', {
          state: {
            data: {
              cng_booking_number: resBook?.data?.Rooms[0]?.ReservationId ?? 0,
              cng_service_id: resBook?.data?.Rooms[0]?.ServiceId ?? 0,
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  //Efetua o procedimento de reserva por cartão de crédito
  const getPlayBooking = async ({ dataForm, customer_id, hotelId, roomId, token, user_id, paymentType }) => {
    try {
      setLoadingB(true);
      await window.scrollTo(0, 0);
      await customToast.toastGetwayToken();
      const res = await getTokenCard({ dataForm, customer_id });
      if ((res && res.status === 201) || (res && res.status === 200)) {
        await setTokenCard(res?.data);
        await setLoadingTokenC(false);
        await setLoadingProfile(true);

        const resP = await getProfile({
          gateway_token: res?.data?.payment_profile?.gateway_token,
          customer_id: customer_id,
        });

        await setProfile(resP?.data);
        await setLoadingProfile(false);
        await customToast.toastPayProfile();
        const cotMarkup = Math.ceil(doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value) * (markupC / 100);
        const result = Math.ceil(cotMarkup);
        const payment = await getPayment({
          gateway_token: res?.data?.payment_profile?.gateway_token,
          customer_id: customer_id,
          amount: cotation === 'USD' ? result : Math.ceil(doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value),
        });

        await setPaymentData(payment?.data);
        if (
          payment?.data?.bill?.charges[0]?.last_transaction?.status === 'success' ||
          payment?.data?.bill?.charges[0]?.last_transaction?.status === 'paid'
        ) {
          await customToast.toastPaymentSucess({
            api_msg: payment?.data?.bill?.charges[0]?.last_transaction?.gateway_message,
          });
          await setCanBook(true);

          //Fazer Reserva
          setLoadingB(true);
          await window.scrollTo(0, 0);

          const resBook = await getDoBooking({ dataForm, hotelId, roomId, token, paymentType });

          if (resBook?.data?.Rooms && resBook?.data?.Rooms[0].ReservationId !== null) {
            await setDoBooking(resBook?.data);
            await setDoBookingStatus(resBook?.status ?? '');
            await setLoadingB(false);
            await customToast.toastSucessBook();
            await setShowCancelButton(true);
            await setBookingId(resBook?.data?.Rooms[0]?.ReservationId);
            await localStorage.setItem('__bookingId', JSON.stringify(resBook?.data?.Rooms[0]?.ReservationId));
            const prod = products && products?.filter(x => x.description == 'Hotelaria');
            const transaction = await getTransaction({
              user_id: user_id,
              product_id: prod?.product_id,
              charge_id: payment?.data?.bill?.charges[0]?.id,
              value: Math.ceil(doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value),
              tid: payment?.data?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.tid,
              vindi_tgw_id_token: token,
              vindi_tid: payment?.data?.bill?.charges[0]?.last_transaction?.id,
              NSU: payment?.data?.bill?.charges[0]?.last_transaction?.gateway_response_fields?.nsu,
            });

            const reservation = await getReservation({
              id: resBook?.data?.RequestId,
              id_user: user_id,
              cng_booking_number: resBook?.data?.Rooms[0]?.ReservationId,
              cng_service_id: resBook?.data?.Rooms[0]?.ServiceId,
              checkin_date: selectedDates[0]
                ? moment(selectedDates[0]).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              checkout_date: selectedDates[1]
                ? moment(selectedDates[1]).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              // cc_id: 0,
              transaction_id: transaction?.data?.transaction_id,
              status: 1,
              label_hotel_name: resBook?.data?.Rooms[0]?.HotelName,
              observation: 'Reserva efetuado com sucesso',
            });

            console.log(reservation, 'reservation');

            const voucher = await getVoucher({ book_id: resBook?.data?.Rooms[0]?.ReservationId, user_id: user_id });
            console.log(voucher, 'voucher');
            if (voucher.status === 200 || voucher.status === 201) {
              await navigate('/bookSuccess', {
                state: {
                  data: {
                    cng_booking_number: resBook?.data?.Rooms[0]?.ReservationId ?? 0,
                    cng_service_id: resBook?.data?.Rooms[0]?.ServiceId ?? 0,
                  },
                },
              });
            }
            window.scrollTo(0, 0);
          } else {
            setBookError(true);
            setLoadingB(false);
            setShowCancelButton(false);
            await localStorage.setItem('__bookingId', null);
            await setLoadingB(false);

            // Caso exista uma transação, ela sera cancelada
            if (payment?.data?.bill?.status !== 'paid') {
              const refund = await getRefund(payment?.data?.bill?.charges[0]?.id);
              return refund;
            }

            setCanBook(false);
            await toast.closeAll();
          }
        } else if (payment?.data?.errors && payment?.data?.errors?.length > 0) {
          await setIsErrorBookIn({
            type: 'dellCharge',
            title: payment?.data?.errors[0]?.message ?? unauth_transaction,
            message: insuficient_founds,
          });
          await toast.closeAll();
          await toast({
            title: payment?.data?.errors[0]?.message ?? unauth_transaction,
            description: insuficient_founds ?? isErrorBookIn?.message,
            status: 'error',
            duration: 4000,
            isClosable: false,
          });
        } else {
          setLoadingB(false);
          setShowCancelButton(false);
          await localStorage.setItem('__bookingId', null);
          await setLoadingB(false);

          // Caso exista uma transação, ela sera cancelada
          if (payment?.data?.bill?.status !== 'paid') {
            const refund = await getRefund(payment?.data?.bill?.charges[0]?.id);
            return refund;
          }

          //Verificar aqui
          await setIsErrorBookIn({
            type: 'internal',
            title: `${payment?.data?.bill?.charges[0]?.last_transaction?.gateway_message}` ?? insuficient_founds,
            message: refund_card,
          });
          await toast.closeAll();
          await toast({
            title: payment?.data?.bill?.charges[0]?.last_transaction?.gateway_message ?? insuficient_founds,
            description: refund_card ?? isErrorBookIn?.message,
            status: 'error',
            duration: 4000,
            isClosable: false,
          });
        }
        await localStorage.setItem('__bookingId', null);
        await setLoadingPayment(false);
      } else {
        setLoadingB(false);
        setShowCancelButton(false);
        await localStorage.setItem('__bookingId', null);
        await setLoadingB(false);

        await setIsErrorBookIn({
          type: 'Error response vindi',
          title: res?.data?.errors[0]?.message ?? insuficient_founds,
          message: refund_card,
        });
        await toast.closeAll();

        await toast({
          title: res?.data?.errors[0]?.message ?? insuficient_founds,
          description: refund_card ?? isErrorBookIn?.message,
          status: 'error',
          duration: 4000,
          isClosable: false,
        });
        await setErrorPlayBook(res?.data);
        throw res;
      }
    } catch (err) {
      await setErrorPlayBook(err?.response?.data);
      setShowCancelButton(false);
      console.log('catch error =>', err);
      await localStorage.setItem('__bookingId', null);

      setLoadingB(false);
      await toast.closeAll();
      await toast({
        title: isErrorBookIn?.title ?? 'Ops! Algo deu errado',
        description: isErrorBookIn?.message ?? 'Tente retornar mas tarde e efetuar uma nova reserva!',
        status: 'error',
        duration: 4000,
        isClosable: false,
      });
    }
  };

  // Efetua o procedimento de reserva por CashClubs e cartão de crédito
  const getPlayBookingPoints = async ({
    dataForm,
    customer_id,
    hotelId,
    roomId,
    token,
    user_id,
    useCard,
    priceCard,
    pricePoints,
    paymentType,
  }) => {
    try {
      setLoadingB(true);
      await window.scrollTo(0, 0);
      //Vai complementar com cartão?
      if (useCard) {
        await customToast.toastGetwayToken();
        const res = await getTokenCard({ dataForm, customer_id });
        if ((res && res.status === 201) || (res && res.status === 200)) {
          await setTokenCard(res?.data);
          await setLoadingTokenC(false);
          await setLoadingProfile(true);

          const resP = await getProfile({
            gateway_token: res?.data?.payment_profile?.gateway_token,
            customer_id: customer_id,
          });

          await setProfile(resP?.data);
          await setLoadingProfile(false);
          await customToast.toastPayProfile();

          const payment = await getPayment({
            gateway_token: res?.data?.payment_profile?.gateway_token,
            customer_id: customer_id,
            amount: Math.ceil(priceCard),
          });

          await setPaymentData(payment?.data);
          if (
            payment?.data?.bill?.charges[0]?.last_transaction?.status === 'success' ||
            payment?.data?.bill?.charges[0]?.last_transaction?.status === 'paid'
          ) {
            const vindi_msg = payment?.data?.bill?.charges[0]?.last_transaction?.gateway_message ?? '';
            await customToast.toastPaymentSucess({ api_msg: vindi_msg });
            await setCanBook(true);
            // COBRANÇA EM CASHCLUBS
            const points = await getPoints({
              user_id: user_id,
              cashclubs: pricePoints,
              transaction_type: 'S',
              abrangency: 'N',
            });
            console.log(points, 'points');
            if (points.status === 201) {
              await customToast.toastSucessPoints();

              //Fazer Reserva
              setLoadingB(true);
              await window.scrollTo(0, 0);
              const resBook = await getDoBooking({ dataForm, hotelId, roomId, token, paymentType });

              if (resBook?.data?.Rooms && resBook?.data?.Rooms[0].ReservationId !== null) {
                return getSucessBook({ resBook, user_id, payment, token, doBookingPolice, points, useCard, priceCard });
              } else {
                setBookError(true);
                setLoadingB(false);
                setShowCancelButton(false);
                await localStorage.setItem('__bookingId', null);
                await setLoadingB(false);
                const pointsRefund = await getPoints({
                  user_id: user_id,
                  cashclubs: pricePoints,
                  transaction_type: 'R',
                  abrangency: 'N',
                });
                console.log(pointsRefund);
                // Caso exista uma transação, ela sera cancelada
                if (payment?.data?.bill?.status !== 'paid') {
                  const refund = await getRefund(payment?.data?.bill?.charges[0]?.id);
                  return refund;
                }

                setCanBook(false);
                await customToast.toastFailBook();
              }
            } else if (points.status !== 200) {
              await customToast.toastFailPoints();
            } else if (payment?.data?.errors && payment?.data?.errors?.length > 0) {
              await setIsErrorBookIn({
                type: 'dellCharge',
                title: payment?.data?.errors[0]?.message ?? unauth_transaction,
                message: insuficient_founds,
              });
              await toast.closeAll();
              await toast({
                title: payment?.data?.errors[0]?.message ?? unauth_transaction,
                description: insuficient_founds ?? isErrorBookIn?.message,
                status: 'error',
                duration: 4000,
                isClosable: false,
              });
            } else {
              setLoadingB(false);
              setShowCancelButton(false);
              await localStorage.setItem('__bookingId', null);
              await setLoadingB(false);

              // Caso exista uma transação, ela sera cancelada
              if (payment?.data?.bill?.status !== 'paid') {
                const refund = await getRefund(payment?.data?.bill?.charges[0]?.id);
                return refund;
              }

              //verificar aqui
              await setIsErrorBookIn({
                type: 'internal',
                title: `${vindi_msg}` ?? insuficient_founds,
                message: refund_card,
              });

              await customToast.toastPaymentFail({
                api_msg: vindi_msg ?? insuficient_founds,
                api_description: refund_card ?? isErrorBookIn?.message,
              });
            }
            await localStorage.setItem('__bookingId', null);
            await setLoadingPayment(false);
          } else {
            await toast({
              title: unauth_transaction,
              description: 'Entre em contato com a Club Share',
              status: 'error',
              duration: 4000,
              isClosable: false,
            });
          }
        } else {
          setLoadingB(false);
          setShowCancelButton(false);
          await localStorage.setItem('__bookingId', null);
          await setLoadingB(false);

          await setIsErrorBookIn({
            type: 'Error response vindi',
            title: res?.data?.errors[0]?.message ?? insuficient_founds,
            message: refund_card,
          });
          await toast.closeAll();

          await toast({
            title: res?.data?.errors[0]?.message ?? insuficient_founds,
            description: refund_card ?? isErrorBookIn?.message,
            status: 'error',
            duration: 4000,
            isClosable: false,
          });
          await setErrorPlayBook(res?.data);
          throw res;
        }
      } else {
        // COBRANÇA APENAS EM CASHCLUBS
        const points = await getPoints({
          user_id: user_id,
          cashclubs: pricePoints,
          transaction_type: 'S',
          abrangency: 'N',
        });
        console.log(points, 'points');
        if (points.status === 200 || points.status === 201) {
          await customToast.toastSucessPoints();

          //Fazer Reserva
          setLoadingB(true);
          await window.scrollTo(0, 0);
          const resBook = await getDoBooking({ dataForm, hotelId, roomId, token, paymentType });

          if (resBook?.data?.Rooms && resBook?.data?.Rooms[0].ReservationId !== null) {
            return getSucessBook({ resBook, user_id, payment: false, token, doBookingPolice, points });
          } else {
            setBookError(true);
            setLoadingB(false);
            setShowCancelButton(false);
            await localStorage.setItem('__bookingId', null);
            await setLoadingB(false);

            const pointsRefund = await getPoints({
              user_id: user_id,
              cashclubs: pricePoints,
              transaction_type: 'R',
              abrangency: 'N',
            });
            console.log(pointsRefund);
            setCanBook(false);
          }
        }
      }
    } catch (err) {
      await setErrorPlayBook(err?.response?.data);
      setShowCancelButton(false);
      console.log('catch error =>', err);
      await localStorage.setItem('__bookingId', null);
      setLoadingB(false);
      await toast.closeAll();
      await toast({
        title: isErrorBookIn?.title ?? 'Ops! Algo deu errado',
        description: isErrorBookIn?.message ?? 'Tente retornar mas tarde e efetuar uma nova reserva!',
        status: 'error',
        duration: 4000,
        isClosable: false,
      });
    }
  };

  useEffect(() => {
    setDestinations(old => old);
  }, [destinations !== '']);

  useEffect(() => {
    getProducts();
  }, [products === []]);

  useEffect(() => {
    localStorage.setItem('__hotel_id', JSON.stringify(destinationId));
  }, [destinationId]);

  useEffect(() => {
    localStorage.setItem('__bookingId', JSON.stringify(bookingId));
  }, [bookingId]);

  useEffect(() => {
    setHotels(old => old);
  }, [hotels]);

  const value = React.useMemo(
    () => ({
      // SEARCH AND HOTELS
      nationality,
      setNationality,
      mnPrice,
      setMnPrice,
      mxPrice,
      setMxPrice,
      nNights,
      setNNights,
      roomOnly,
      setRoomOnly,
      sR,
      setSR,
      hotels,
      setHotels,
      destinations,
      setDestinations,
      destinationId,
      setDestinationID,
      hotelID,
      setHotelID,
      search,
      setSearch,
      selectedDates,
      setSelectedDates,
      rooms,
      setRooms,
      loading,
      setLoading,
      loadingD,
      error,
      getSearchHotels,
      onChanged,
      getHotelDetailsCangoroo,
      hotelDetailsC,
      setHotelDetailsC,
      filterPrice,
      setFilterPrice,
      loadingC,
      setLoadingC,
      isFirstSrc,
      setIsFirstSrc,

      //DOBOOKING
      showCancelButton,
      setShowCancelButton,
      doBookingPolice,
      setDoBookingPolice,
      getBookingConditions,
      doBooking,
      setDoBooking,
      doBookingStatus,
      setDoBookingStatus,
      isBooking,
      setIsBooking,
      token,
      setToken,
      loadingB,
      setLoadingB,
      loadingCancel,
      setLoadingCancel,
      cancel,
      setCancel,
      resetTimer,
      setResetTimer,

      //VINDI
      // getPaymentData,
      paymentData,
      setPaymentData,
      loadingPayment,
      setLoadingPayment,
      getPlayBooking,
      tokenCard,
      setTokenCard,
      loadingTokenC,
      setLoadingTokenC,
      // getPaymentProfile,
      profile,
      setProfile,
      loadingProfile,
      setLoadingProfile,
      canBook,
      setCanBook,
      bookError,
      setBookError,

      errorPlayBook,
      setErrorPlayBook,
      getPlayBookingPoints,
    }),
    [
      mnPrice,
      setMnPrice,
      mxPrice,
      setMxPrice,
      nNights,
      setNNights,
      roomOnly,
      setRoomOnly,
      sR,
      setSR,
      hotels,
      setHotels,
      destinations,
      setDestinations,
      destinationId,
      setDestinationID,
      hotelID,
      setHotelID,
      search,
      setSearch,
      selectedDates,
      setSelectedDates,
      rooms,
      setRooms,
      loading,
      setLoading,
      loadingD,
      error,
      getSearchHotels,
      onChanged,
      getHotelDetailsCangoroo,
      hotelDetailsC,
      setHotelDetailsC,
      filterPrice,
      setFilterPrice,
      loadingC,
      setLoadingC,
      isFirstSrc,
      setIsFirstSrc,
      //doBooking
      showCancelButton,
      setShowCancelButton,
      doBookingPolice,
      setDoBookingPolice,
      getBookingConditions,
      // getDoBooking
      doBooking,
      setDoBooking,
      doBookingStatus,
      setDoBookingStatus,
      isBooking,
      setIsBooking,
      token,
      setToken,
      loadingB,
      setLoadingB,
      loadingCancel,
      setLoadingCancel,
      // getCancelAllHotelServices,
      cancel,
      setCancel,
      resetTimer,
      setResetTimer,
      bookError,
      setBookError,

      //VINDI
      // getPaymentData,
      paymentData,
      setPaymentData,
      loadingPayment,
      setLoadingPayment,
      getPlayBooking,
      tokenCard,
      setTokenCard,
      loadingTokenC,
      setLoadingTokenC,
      // getPaymentProfile,
      profile,
      setProfile,
      loadingProfile,
      setLoadingProfile,
      canBook,
      setCanBook,

      errorPlayBook,
      setErrorPlayBook,
      getPlayBookingPoints,
    ],
  );

  return (
    <SearchContext.Provider value={value !== undefined && value}>
      {React.useMemo(() => children, [])}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('The context The Search must be within a valid provider');
  }
  return context;
}
