import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, Button, Flex, Text, Input, Select, Alert, AlertIcon } from '@chakra-ui/react';
import { apiCPT } from '../../../services/api';

export default function InfoBasicas({
  enterprises,
  loadLocalStorage,
  setLoadLocalStorage,
  setApplyNextStep,
  setDepositData,
}) {
  const [enterpriseId, setEnterpriseId] = useState(null);
  const [enterpriseCotas, setEnterpriseCotas] = useState([]);
  const [habitationalUnityId, setHabitationalUnityId] = useState(0);
  const [itemsWeeks, setItemsWeeks] = useState([
    {
      onwership_id: null,
      indice: 0,
      week_cota: null,
      rooms: null,
      guests: null,
      check_in: null,
      check_out: null,
      viajex: null,
    },
  ]);
  const [loading, setLoading] = useState(false); // Novo estado para controlar o carregamento

  // Filtrar cotas duplicadas
  function deleteDuplicates(data) {
    return data.filter(
      (obj, index, self) => index === self.findIndex(t => t.id === obj.id && t.enterprise_id === obj.enterprise_id),
    );
  }

  // Filtrar cotas da empresa selecionada
  function filterCotas() {
    setEnterpriseCotas(enterprises.filter(p => p.enterprise_id == enterpriseId));
  }

  // Verificar se o formulário está completo
  function verifyCompletedForm() {
    const incompleteWeeks = itemsWeeks.filter(
      p =>
        p.week_cota == null ||
        p.rooms == null ||
        p.guests == null ||
        p.check_in == null ||
        p.check_out == null ||
        p.viajex === null,
    );
    return incompleteWeeks.length === 0;
  }

  // Atualiza cotas quando o enterpriseId é alterado
  useEffect(() => {
    if (enterpriseId > 0) {
      filterCotas();
    }
  }, [enterpriseId, habitationalUnityId]);

  // Salvar no localStorage quando as semanas mudarem
  useEffect(() => {
    if (loadLocalStorage === false) {
      localStorage.setItem('__deposit_of_days', JSON.stringify(itemsWeeks));
    }
    setApplyNextStep(verifyCompletedForm());
  }, [itemsWeeks]);

  // Carregar semanas do localStorage
  useEffect(() => {
    if (loadLocalStorage === true) {
      const data = JSON.parse(localStorage.getItem('__deposit_of_days'));
      setItemsWeeks(data);
      setLoadLocalStorage(false);
    }
  }, [loadLocalStorage]);

  // Calcular a data de checkout
  const calculateCheckoutDate = checkinDate => {
    return moment(checkinDate).add(7, 'days').format('YYYY-MM-DD');
  };

  // Adicionar nova semana
  const handleAddWeek = () => {
    setItemsWeeks(prevWeeks => [
      ...prevWeeks,
      {
        onwership_id: null,
        indice: prevWeeks.length,
        week_cota: null,
        rooms: null,
        guests: null,
        check_in: null,
        check_out: null,
        viajex: null,
      },
    ]);
  };

  // Atualizar campos de uma semana específica
  const handleChangeItemWeek = (indice, field, value) => {
    setItemsWeeks(prevWeeks =>
      prevWeeks.map((week, i) => {
        if (i === indice) {
          let updatedWeek = { ...week, [field]: value };

          if (field === 'week_cota') {
            const valueData = value.split(',');
            updatedWeek.onwership_id = valueData[0];
            updatedWeek.week_cota = valueData[1];
            updatedWeek.rooms = enterprises.find(e => e.enterprise_id == enterpriseId)?.habitational_unity.room_type;
            updatedWeek.guests = enterprises.find(e => e.enterprise_id == enterpriseId)?.habitational_unity.pax;
          }

          if (field === 'check_in') {
            const checkOutDate = calculateCheckoutDate(value);
            updatedWeek.check_in = value;
            updatedWeek.check_out = checkOutDate; // Atualiza automaticamente o campo check_out

            setLoading(true);

            // Atualiza o ano de acordo com o check_in
            const year = moment(value).year();

            // Requisição para buscar cotação
            apiCPT
              .get(
                `v1/extract/weekly_deposits/${enterpriseId}?habitational_unit_id=${habitationalUnityId}&pageNumber=1&perPage=10&ano=${year}`,
              )
              .then(res => {
                const data = res.data?.quotation?.filter(
                  p => p.value_amount > 0 && p.validation_status == 1 && verifyIsValidCheckinDate(p, value),
                );

                // Se encontrarmos uma semana válida
                if (data.length > 0) {
                  // Atualiza o viajex
                  setItemsWeeks(prevWeeks =>
                    prevWeeks.map((week, i) => {
                      if (i === indice) {
                        return {
                          ...week,
                          viajex: data[0].value_amount,
                          check_in: moment(data[0].week_starts_at).format('YYYY-MM-DD'),
                          check_out: moment(data[0].week_end_at).format('YYYY-MM-DD'), // Atualiza as datas corretas
                        };
                      }
                      return week;
                    }),
                  );
                } else {
                  // Define viajex como 0 se não houver cotação
                  setItemsWeeks(prevWeeks =>
                    prevWeeks.map((week, i) => {
                      if (i === indice) {
                        return {
                          ...week,
                          viajex: 0,
                        };
                      }
                      return week;
                    }),
                  );
                }
                setLoading(false);
                setApplyNextStep(verifyCompletedForm());
              });
          }

          return updatedWeek;
        } else {
          return week;
        }
      }),
    );
  };

  useEffect(() => {
    setDepositData(itemsWeeks);
  }, [itemsWeeks]);

  const verifyIsValidCheckinDate = (obj, checkInDate) => {
    const compareDate = moment(checkInDate);
    const startDate = moment(obj.week_starts_at);
    const endDate = moment(obj.week_end_at);

    // Verifica se o check-in está dentro da semana ou se está muito próximo
    return compareDate.isSameOrAfter(startDate) && compareDate.isBefore(endDate);
  };

  return (
    <Box p={6} w="100%" h="100%">
      {enterprises && (
        <>
          <Flex w="100%">
            <Box p={2} w="100%">
              <Text>Empreendimento</Text>
              <Select
                size="md"
                placeholder="Selecione"
                value={enterpriseId}
                onChange={e => {
                  setEnterpriseId(e.target.value);
                  setHabitationalUnityId(
                    enterprises.find(p => p.enterprise_id == e.target.value)?.habitational_unity_id,
                  );
                  setItemsWeeks([
                    {
                      onwership_id: null,
                      indice: 0,
                      week_cota: null,
                      rooms: null,
                      guests: null,
                      check_in: null,
                      check_out: null,
                      viajex: null,
                    },
                  ]);
                }}
              >
                {deleteDuplicates(enterprises).map((item, index) => (
                  <option key={index} value={item.enterprise_id}>
                    {item.enterprise.enterprise_name}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          <Flex w="100%">
            <Box p={2} w="100%">
              <Text fontWeight={700}>Informações básicas do hotel</Text>
            </Box>
          </Flex>
          <Button ml={4} onClick={handleAddWeek} colorScheme="red">
            Adicionar mais Semanas
          </Button>
          {itemsWeeks.map((p, indice) => (
            <React.Fragment key={indice}>
              <Flex w="100%">
                <Box p={2} w="100%">
                  <Flex w="100%">
                    <Box w="20%" p={2}>
                      <Text>Cota</Text>
                      <Select
                        size="md"
                        placeholder="Selecione"
                        value={p.week_cota ? [p.onwership_id, p.week_cota] : ''}
                        onChange={e => handleChangeItemWeek(indice, 'week_cota', e.target.value)}
                      >
                        {enterpriseCotas?.map((item, index) => (
                          <option key={index} value={[item.onwership_id, item.which_one_hu]}>
                            {item.which_one_hu}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box w="15%" p={2}>
                      <Text>Quartos</Text>
                      <Input type="text" disabled value={p.rooms} />
                    </Box>
                    <Box w="20%" p={2}>
                      <Text>Hóspedes</Text>
                      <Input type="number" disabled value={p.guests} />
                    </Box>
                    <Box w="22.5%" p={2}>
                      <Text>Dt. Check-in</Text>
                      <Input
                        type="date"
                        value={p.check_in || ''}
                        onChange={e => handleChangeItemWeek(indice, 'check_in', e.target.value)} // Atualiza check-in
                      />
                    </Box>
                    <Box w="22.5%" p={2}>
                      <Text>Dt. Check-out</Text>
                      <Input type="date" value={p.check_out || ''} disabled /> {/* Atualiza check-out */}
                    </Box>
                  </Flex>

                  <Flex w="100%">
                    <Box w="20%" p={2}>
                      <Text>Viajex</Text>
                      <Input type="number" value={p.viajex !== null ? p.viajex : ''} disabled />
                    </Box>
                  </Flex>

                  {!loading && p.viajex === 0 && (
                    <Flex w="100%">
                      <Box w="100%" p={2}>
                        <Alert status="error">
                          <AlertIcon />
                          Esta data pode não ter sido cotada! Favor entrar em contato com o nosso atendimento via
                          WhatsApp ou Telefone.
                        </Alert>
                      </Box>
                    </Flex>
                  )}
                </Box>
              </Flex>
            </React.Fragment>
          ))}
        </>
      )}
    </Box>
  );
}
